<template>
  <div>
    <CommonStandardList
        slug="Attendance"
        :module-name="STORE_MODULE_NAME"
        :table-headers="tableHeaders"
        :form-setting="form"
        :api-urls="apiUrls"
    />
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {onUnmounted, ref} from "@vue/composition-api"

// sidebar
import CommonStandardList from "@/views/common/list/standard-list/CommonStandardList"
import store from "@/store"
import companyStoreModule from "@/views/company-management/companies/companyStoreModule"
import useCompaniesList from "@/views/company-management/companies/company-list/useCompaniesList"
import useCompanyConfigList from "@/views/company-management/companies/company-config/useCompanyConfigList"

export default {
  components: {
    CommonStandardList
  },
  mounted() {
    this.fetchCompanies()
  },
  setup() {
    const STORE_MODULE_NAME = "em-employee-attendance"

    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, companyStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    })

    const {
      allCompanyNames,
      fetchCompanies
    } = useCompanyConfigList({moduleName: STORE_MODULE_NAME})

    const apiUrls = ref(
        {
          list: "/admin/attendance",
          add: "/admin/attendance"
        }
    )

    const employmentType = ref([
      "PERMANENT",
      "PROBATION",
      "THIRD-PARTY"
    ])

    const tableHeaders = ref([
      {text: "Company Name", value: "company_name", filter: true},
      {text: "Employee Name", value: "employee_name"},
      {text: "Date", value: "attendance_date"},
      {text: "IN TIME", value: "in_time"},
      {text: "OUT TIME", value: "out_time"},
      {text: "ATTENDANCE COVERAGE", value: "coverage"},
      {text: "Created At", value: "created_at", sortable: false},
      {
        text: "ACTIONS",
        value: "actions",
        align: "center",
        sortable: false,
      },
    ])

    const form = ref([
      {name: "company_name", type: "select", required: true, options: allCompanyNames},
      {name: "minimum_working_days", type: "text", required: true},
      {name: "salary_min", type: "text", required: true},
      {name: "salary_max", type: "text", required: true},
      {name: "minimum_service_length", type: "text", required: true},
      {name: "allow_employment_type", type: "select", required: true, options: employmentType},
      {name: "current_month_working_days", type: "text", required: true},
      {name: "allowed_disbursement_percentage", type: "text", required: true},
      {name: "allow_applications_per_month_by_employee", type: "text", required: true}
    ])

    const searches = []

    return {
      STORE_MODULE_NAME,
      tableHeaders,
      form,
      apiUrls,
      fetchCompanies,
      // icons
      icons: {},
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
